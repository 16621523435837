import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import './ProductDetail.scss';
import {
  FlexContLeft,
  ImgButton,
  ImgButtonPersonalization,
} from '../../styled-components';
import { useOrder } from '../../commons/Order';
import { getSvgComponent } from '../../lib/helpers/svgComponents';
import { yourDesignsEffect } from '../../lib/helpers/yourDesigns';

function TabDesign(props) {
  const {
    currentDesign = {},
    inspirations = [],
    item = 0,
    yourDesigns = [],
  } = props;
  const [defaultDesign] = inspirations;
  const { parts: currentDesignParts = [] } = currentDesign;
  const [t] = useTranslation('DETAIL');
  const [activeTab, setActiveTab] = useState('1');
  const [, dispatch] = useOrder();

  useEffect(() => {
    if (currentDesignParts.length) return;

    dispatch({
      type: 'setCurrentDesign',
      item,
      design: defaultDesign,
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    yourDesignsEffect({
      yourDesigns,
    }),
    [yourDesigns]
  );

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              toggle('1');
            }}
          >
            {t('INSPIRATION')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => {
              toggle('2');
            }}
          >
            {t('YOUR_DESIGN')}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <FlexContLeft>
            {inspirations.map((design, index) => (
              <ImgButton
                key={index}
                onClick={() =>
                  dispatch({
                    type: 'setCurrentDesign',
                    item,
                    design: {
                      ...design,
                      parts: design.parts.map((part) => ({
                        ...part,
                        hex: part.hexToApply || part.hex,
                      })),
                    },
                  })
                }
              >
                <img src={design.src} className="img-fluid" alt="" />
              </ImgButton>
            ))}
          </FlexContLeft>
        </TabPane>
        <TabPane tabId="2">
          <FlexContLeft>
            {yourDesigns.map((design, index) => (
              <ImgButtonPersonalization
                key={index}
                onClick={() =>
                  dispatch({
                    type: 'setCurrentDesign',
                    item,
                    design,
                  })
                }
              >
                {getSvgComponent(0, `position-svg-icon your-design-${index}`)}
              </ImgButtonPersonalization>
            ))}
            {!yourDesigns || !yourDesigns.length ? (
              <span>{t('YOUR_DESIGNS_PLACEHOLDER')}</span>
            ) : (
              ''
            )}
          </FlexContLeft>
        </TabPane>
      </TabContent>
    </>
  );
}

export default TabDesign;
