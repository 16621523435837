import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { QueryParamProvider } from 'use-query-params';
import { Provider as AlertProvider, positions } from 'react-alert';
import classnames from 'classnames';
import { IS_WINDOWS } from './lib/helpers/platformDetector';
import { LoadingProvider } from './commons/Loading';
import { OrderProvider } from './commons/Order';
import AlertTemplate from './commons/AlertTemplate';
import Dashboard from './pages/Dashboard';
import ProductDetail from './pages/ProductDetail';
import Configurator from './pages/Configurator';
import PageNotFound from './pages/PageNotFound';
import translations from './translations';
import CookieBanner from './components/CookieBanner';
import './App.scss';

i18next.init({
  resources: translations,
  lng: 'it',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  return (
    <div
      className={classnames({
        App: true,
        'non-windows-body': !IS_WINDOWS,
      })}
    >
      <I18nextProvider i18n={i18next}>
        <AlertProvider
          template={AlertTemplate}
          position={positions.TOP_RIGHT}
          timeout={5000}
        >
          <LoadingProvider>
            <OrderProvider>
              <Router>
                <QueryParamProvider ReactRouterRoute={Route}>
                  <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route path="/product-detail" component={ProductDetail} />
                    <Route path="/configurator" component={Configurator} />
                    <Route component={PageNotFound} />
                  </Switch>
                </QueryParamProvider>
              </Router>
            </OrderProvider>
          </LoadingProvider>
        </AlertProvider>
      </I18nextProvider>
      <CookieBanner />
    </div>
  );
}

export default App;
