import cloneDeep from 'lodash.clonedeep';

const initialState = {};

function orderReducer(state, action = {}) {
  const {
    type,
    design,
    colorName,
    hex,
    item,
    part,
    price,
    size,
    backgroundImages,
  } = action;

  switch (type) {
    case 'clear':
      return { ...initialState };
    case 'setSize':
      return {
        ...state,
        size,
      };
    case 'setCurrentDesign':
      return {
        ...state,
        currentDesign: { ...cloneDeep(design), item },
      };
    case 'setBackupDesign':
      return {
        ...state,
        backupDesign: { ...cloneDeep(design), item },
      };
    case 'unsetBackupDesign':
      return {
        ...state,
        setBackupDesign: undefined,
      };
    case 'setPart':
      return {
        ...state,
        currentPart: part,
      };
    case 'unsetPart':
      return {
        ...state,
        currentPart: undefined,
      };
    case 'setPartColor':
      const { currentDesign = {}, currentPart = {} } = state;
      const {
        backgroundImages: currentBackgroundImages,
        item: currentDesignItem = item,
        parts = [],
      } = currentDesign;
      currentDesign.parts = parts;
      let foundPart = parts.find(({ name }) => name === currentPart.name);
      if (!foundPart) {
        foundPart = currentPart;
        parts.push(foundPart);
      }
      foundPart.colorName = colorName;
      foundPart.hex = hex;
      foundPart.price = price;
      return {
        ...state,
        currentDesign: {
          ...currentDesign,
          item: currentDesignItem,
          parts: [...parts],
          backgroundImages: backgroundImages || currentBackgroundImages,
        },
      };
    case 'saveDesign':
      const { yourDesigns = [] } = state;
      yourDesigns.push(cloneDeep(design));
      return {
        ...state,
        yourDesigns,
      };
    default:
      return { ...state };
  }
}

export { initialState, orderReducer };
