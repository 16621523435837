import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { useOrder } from '../../commons/Order';

function ComponentsList(props) {
  const { parts } = props;
  const [t] = useTranslation('CONFIGURATOR');
  const [, dispatch] = useOrder();

  return (
    <div className="customizable-components-list">
      {parts.map((part, index) => (
        <Button
          key={index}
          onClick={() =>
            dispatch({
              type: 'setPart',
              part,
            })
          }
        >
          <img src={part.src} className="img-fluid" alt="" />
          <p>{t(part.key)}</p>
        </Button>
      ))}
    </div>
  );
}

export default ComponentsList;
