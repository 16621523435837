import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import './Configurator.scss';
import { ContConfigurator, BlackButton } from '../../styled-components';
import { useOrder } from '../../commons/Order';
import ComponentsList from './ComponentsList';
import Configurations from './Configurations';
import CarouselConfiguration from './CarouselConfiguration';

function ConfiguratorPresentational(props) {
  const { model = {}, item = 0, orderData = {} } = props;
  const { parts = [] } = model;
  const { backupDesign, currentDesign, currentPart } = orderData;
  const [t] = useTranslation('CONFIGURATOR');
  const [, dispatch] = useOrder();

  return (
    <div className="App">
      <section>
        <ContConfigurator>
          <div className="custom-img-cont">
            <div className="carousel-configuration-body">
              <CarouselConfiguration
                item={item}
                orderData={orderData}
                model={model}
              />
            </div>
            <div className="carousel-configuration-footer">
              <Link
                to={'/product-detail?item=0'}
                onClick={() =>
                  dispatch({
                    type: 'saveDesign',
                    design: currentDesign,
                  })
                }
              >
                <BlackButton>{t('COMMON:END')}</BlackButton>
              </Link>
            </div>
          </div>

          <div className="configuration-cont">
            {currentPart ? (
              <Configurations
                backupDesign={backupDesign}
                currentDesign={currentDesign}
                currentPart={currentPart}
                model={model}
                item={item}
              />
            ) : (
              <ComponentsList parts={parts} />
            )}
          </div>
        </ContConfigurator>
      </section>
    </div>
  );
}

export default withRouter(ConfiguratorPresentational);
