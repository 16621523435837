import { ReactComponent as AirLanderSvg1 } from '../../images/airlander-plus/airlander-plus1.svg';
import { ReactComponent as AirLanderSvg2 } from '../../images/airlander-plus/airlander-plus2.svg';
import { ReactComponent as AirLanderSvg3 } from '../../images/airlander-plus/airlander-plus3.svg';
import { ReactComponent as AirLanderSvg4 } from '../../images/airlander-plus/airlander-plus4.svg';
import { ReactComponent as AirLanderSvg5 } from '../../images/airlander-plus/airlander-plus5.svg';
import { ReactComponent as AirLanderSvg6 } from '../../images/airlander-plus/airlander-plus6.svg';
import React from 'react';

export const getSvgComponent = (index, className = '') => {
  switch (index) {
    case 0:
      return <AirLanderSvg1 className={className} />;
    case 1:
      return <AirLanderSvg2 className={className} />;
    case 2:
      return <AirLanderSvg3 className={className} />;
    case 3:
      return <AirLanderSvg4 className={className} />;
    case 4:
      return <AirLanderSvg5 className={className} />;
    case 5:
      return <AirLanderSvg6 className={className} />;
    default:
      return null;
  }
};
