import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Menu from '../../commons/Menu';
import Footer from '../../commons/Footer';
import './Dashboard.scss';
import {
  BlackCont,
  Content,
  H3Styled,
  PStyled,
  RedButton,
} from '../../styled-components';
import { Row, Col } from 'reactstrap';

function DashboardPresentational({ match, catalog = [] }) {
  const [t, { language }] = useTranslation('INDEX');

  return (
    <div className="App">
      <Menu match={match} />

      <section>
        <div className="img-open" />
      </section>

      <section>
        <BlackCont>
          <h2>{t('CUSTOM')}</h2>
        </BlackCont>

        <Content>
          <H3Styled style={{ fontSize: '40px' }}>{t('CREATE_TITLE')}</H3Styled>
          <PStyled dangerouslySetInnerHTML={{ __html: t('CREATE_TEXT') }} />

          <Row>
            {catalog.map(({ animation, alt, title }, index) => (
              <Col xs="12" key={index}>
                <img
                  src={animation}
                  alt={alt}
                  title={title}
                  className="u-center-horizontal img-fluid gif-index"
                />
              </Col>
            ))}
          </Row>

          <Link to={`/product-detail?item=0&lang=${language}`}>
            <RedButton className="u-center-horizontal">
              {t('INDEX:CUSTOM')}
            </RedButton>
          </Link>
        </Content>
      </section>

      <Footer />
    </div>
  );
}

export default withRouter(DashboardPresentational);
