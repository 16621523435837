import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';
import './Lang.scss';
import classnames from 'classnames';
import translations from '../../translations';

const supportedLangs = Object.keys(translations);

function Lang() {
  const [, i18n] = useTranslation();
  const lang = i18n.language;
  const [{ lang: queryLang }, setQueryParams] = useQueryParams({
    lang: StringParam,
  });

  useEffect(() => {
    if (!supportedLangs.includes(queryLang)) return;
    i18n.changeLanguage(queryLang);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang]);

  return (
    <div className="btn-lang">
      <button
        className={classnames({ active: lang === 'it' })}
        onClick={() => setQueryParams({ lang: 'it' })}
      >
        IT
      </button>{' '}
      |
      <button
        className={classnames({ active: lang === 'de' })}
        onClick={() => setQueryParams({ lang: 'de' })}
      >
        DE
      </button>{' '}
      |
      <button
        className={classnames({ active: lang === 'en' })}
        onClick={() => setQueryParams({ lang: 'en' })}
      >
        EN
      </button>
      |
      <button
        className={classnames({ active: lang === 'fr' })}
        onClick={() => setQueryParams({ lang: 'fr' })}
      >
        FR
      </button>{' '}
    </div>
  );
}

export default Lang;
