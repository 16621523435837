import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { useOrder } from '../../commons/Order';
import { ReactComponent as IconCancel } from '../../images/icn_cancel.svg';
import { ReactComponent as IconCheck } from '../../images/icn_check.svg';

function Configurations(props) {
  const {
    backupDesign = {},
    currentDesign = {},
    currentPart = {},
    item = 0,
    model = {},
  } = props;
  const { key = '', colors = [] } = currentPart;
  const { parts: currentDesignParts = [] } = currentDesign;
  const { inspirations = [] } = model;
  const [defaultDesign] = inspirations;
  const [t] = useTranslation('CONFIGURATOR');
  const [, dispatch] = useOrder();

  useEffect(() => {
    if (currentDesignParts.length) return;
    dispatch({
      type: 'setCurrentDesign',
      item,
      design: defaultDesign,
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="configurations-detail">
      <div className="customization-header">
        <span>{t(key)}</span>
      </div>
      <div className="customization-body">
        {colors.map(
          (
            {
              name = '',
              hex = '',
              hexToApply = '',
              price = 0,
              backgroundImages,
            },
            index
          ) => (
            <Button
              key={index}
              className="color-settings-cont"
              onClick={() => {
                dispatch({
                  type: 'setBackupDesign',
                  design: currentDesign,
                });
                dispatch({
                  type: 'setPartColor',
                  colorName: name,
                  hex: hexToApply || hex,
                  item,
                  price,
                  backgroundImages,
                });
              }}
            >
              <div className="color" style={{ backgroundColor: hex }} />
              <span>{name}</span>
            </Button>
          )
        )}
      </div>
      <div className="customization-footer">
        <Button
          className="cancel"
          onClick={() => {
            dispatch({
              type: 'setCurrentDesign',
              item,
              design: backupDesign,
            });
            dispatch({
              type: 'unsetPart',
            });
          }}
        >
          <IconCancel />
        </Button>

        <Button
          onClick={() =>
            dispatch({
              type: 'unsetPart',
            })
          }
        >
          <IconCheck />
        </Button>
      </div>
    </div>
  );
}

export default Configurations;
