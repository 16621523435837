import React from 'react';
import { Redirect } from 'react-router-dom';
import { useQueryParams, NumberParam } from 'use-query-params';
import { useOrder } from '../../commons/Order';
import ConfiguratorPresentational from './ConfiguratorPresentational';
import catalog from '../../catalog.json';

function Configurator(props) {
  const [{ item }] = useQueryParams({
    item: NumberParam,
  });
  const [orderData] = useOrder();

  if (typeof item !== 'number' || !catalog[item]) {
    return <Redirect to="/" />;
  }

  return (
    <ConfiguratorPresentational
      model={catalog[item]}
      orderData={orderData}
      {...props}
    />
  );
}

export default Configurator;
