export default {
  black: '#111111',
  blackLabel: '#333333',
  blackSelect: '#666666',
  white: '#ffffff',
  background: '#F6F6F6',
  backgroundImg: '#f0f0f0',
  greyDark: '#4C4C4C',
  grey: '#CCCCCC',
  greyLight: '#E5E5E5',
  primary: '#BE0E10',
  footerTxt: '#949494',

  HelveticaNeueRoman: 'Helvetica Neue Roman',
  HelveticaNeueMedium: 'Helvetica Neue Medium',
  HelveticaNeueLTBold: 'Helvetica Neue LT Std Bold',
  HelveticaNeueLTRegular: 'Helvetica Neue LT Std',
};
