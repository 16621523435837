import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useAlert } from 'react-alert';
import Menu from '../../commons/Menu';
import Footer from '../../commons/Footer';
import { formatCurrency } from '../../lib/helpers/formatters';
import { getSvgComponent } from '../../lib/helpers/svgComponents';
import { IS_WINDOWS } from '../../lib/helpers/platformDetector';
import './ProductDetail.scss';
import {
  GreyCont,
  Content,
  BackButton,
  H2Styled,
  H6Styled,
  SpaceBetweenCont,
  PStyled,
  White100Button,
  Black100Button,
  CustomButton,
  ContImg,
  SizeLabel,
  SizeSelect,
} from '../../styled-components';
import { Row, Col } from 'reactstrap';
import TabDesign from './TabDesign';
import ShowMoreFeatures from './ShowMoreFeatures';
import { ReactComponent as IconCustom } from '../../images/personalizza.svg';
import { currentDesignEffect } from '../../lib/helpers/currentDesign';
import { getTotalPrice } from '../../lib/helpers/totalPrice';
import { useOrder } from '../../commons/Order';

function ProductDetailPresentational(props) {
  const {
    match,
    model = {},
    item = 0,
    orderData = {},
    onAddToCart = () => {},
  } = props;
  const [t, { language }] = useTranslation('DETAIL');
  const {
    images = [],
    inspirations = [],
    title = '',
    subtitle = {},
    sizes = [],
  } = model;
  const { currentDesign = {}, yourDesigns = [], size = {} } = orderData;
  const { backgroundImages = [] } = currentDesign;
  const [defaultDesign] = inspirations;
  const { parts: defaultParts = [] } = defaultDesign;
  const totalPrice = getTotalPrice(size, currentDesign || defaultDesign);
  const [, dispatch] = useOrder();
  const alert = useAlert();

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 500);
  }, []);

  useEffect(() => {
    if (size && Object.keys(size).length) return;
    dispatch({
      type: 'setSize',
      size: sizes[0],
    });
  }, [size]);

  useEffect(
    currentDesignEffect({
      currentDesign,
      item,
      images,
      defaultParts,
    }),
    [currentDesign]
  );

  return (
    <div className="App">
      <Menu match={match} />

      <section className="padding-menu">
        <GreyCont>
          <Link to={'/'}>
            <BackButton>
              <i className="fas fa-chevron-left" /> {t('COMMON:BACK')}
            </BackButton>
          </Link>
        </GreyCont>

        <Content>
          <Row>
            <Col sm="12" md="12" lg="8">
              <Row>
                <Link to={`/configurator?item=${item}`}>
                  <CustomButton>
                    <IconCustom /> <span>{t('CUSTOMIZE')}</span>
                  </CustomButton>
                </Link>
                {images.map((image, index) => {
                  const { alt = '', title = '', src = '' } = image;
                  return (
                    <Col xs="6" key={index}>
                      <ContImg>
                        <img
                          src={backgroundImages[index] || src}
                          alt={alt}
                          title={title}
                          className="img-fluid"
                          style={{ position: 'relative' }}
                        />
                        {getSvgComponent(index, 'position-svg')}
                      </ContImg>
                    </Col>
                  );
                })}
              </Row>
            </Col>
            <Col sm="12" md="12" lg="4">
              <SpaceBetweenCont>
                <H6Styled>
                  {subtitle && subtitle[language] || ''}
                </H6Styled>
              </SpaceBetweenCont>
              <SpaceBetweenCont>
                <H2Styled>{title}</H2Styled>
                <H2Styled>
                  {totalPrice ? formatCurrency(totalPrice) : ''}
                </H2Styled>
              </SpaceBetweenCont>

              <TabDesign
                currentDesign={currentDesign}
                inspirations={model.inspirations}
                item={item}
                yourDesigns={yourDesigns}
              />

              <div className="m-t-b-40">
                <Link to={`/configurator?item=${item}`}>
                  <White100Button className={IS_WINDOWS ? 'windows' : ''}>
                    {t('CUSTOMIZE')} <IconCustom />
                  </White100Button>
                </Link>
                <SizeLabel>
                  {t('SELECT_SIZE').toUpperCase()}
                </SizeLabel>
                <SizeSelect
                  onChange={({ target: { value = '' } }) => {
                    const selectedSize = sizes.find(
                      ({ name }) => name === value
                    );
                    dispatch({
                      type: 'setSize',
                      size: selectedSize,
                    });
                  }}
                  value={size.name || ''}
                >
                  {sizes.map(({ name }, index) => (
                    <option key={index} value={name}>
                      {(name || '').toUpperCase()}
                    </option>
                  ))}
                </SizeSelect>
                <Black100Button
                  className={IS_WINDOWS ? 'windows' : ''}
                  onClick={() => {
                    size.name
                      ? onAddToCart()
                      : alert.error(t('SELECT_SIZE_ERR'));
                  }}
                >
                  {t('ADD_TO_CART')}
                </Black100Button>
              </div>

              <PStyled
                dangerouslySetInnerHTML={{ __html: t('AIRLANDER_DESCRIPTION') }}
                className="m-b-30"
              />
              <hr />
              <ShowMoreFeatures />
              <hr />
            </Col>
          </Row>
        </Content>
      </section>

      <Footer />
    </div>
  );
}

export default withRouter(ProductDetailPresentational);
