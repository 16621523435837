import React from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavItem, NavLink } from 'reactstrap';
import './LinkMenu.scss';

const { REACT_APP_EXIT_URL } = process.env;

const LinkMenu = () => {
  const [t, { language }] = useTranslation('COMMON');
  const exitUrl = `${REACT_APP_EXIT_URL}/${language}/`;

  return (
    <NavItem>
      <NavLink href={exitUrl} target="_blank">
        <i className="fas fa-times" /> {t('EXIT_CONFIGURATION')}
      </NavLink>
    </NavItem>
  );
}

export default withRouter(LinkMenu);
