import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { DetailButton, PStyled, H3Styled } from '../../styled-components';
import close from '../../images/icon_chiusura.png';

const ShowMoreFeatures = () => {
  const [t] = useTranslation('DETAIL');

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const closeBtn = (
    <button className="close" onClick={toggle}>
      <img src={close} alt="" className="img-fluid" />
    </button>
  );

  return (
    <>
      <DetailButton color="danger" onClick={toggle}>
        {t('DETAIL_PRODUCT')}
      </DetailButton>
      <Modal isOpen={modal} toggle={toggle} className="width-80">
        <ModalHeader toggle={toggle} close={closeBtn}>
          <H3Styled>{t('DETAIL_TITLE')}</H3Styled>
        </ModalHeader>
        <ModalBody>
          <PStyled
            dangerouslySetInnerHTML={{
              __html: t('DETAIL_DESCRIPTION_AIRLANDER_PLUS'),
            }}
          />
          <H3Styled>{t('DETAIL_TITLE2')}</H3Styled>
          <PStyled
            dangerouslySetInnerHTML={{
              __html: t('DETAIL_DESCRIPTION2_AIRLANDER_PLUS'),
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default ShowMoreFeatures;
