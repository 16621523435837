// const ANDROID_REGEX = /android/i;
const IOS_REGEX = /iPad|iPhone|iPod/i;
const WINDOWS_REGEX = /Win32|Win64|Windows/i;
const { platform = '', userAgent = '' } = navigator;
// const { MSStream } = window;
// const IS_ANDROID =
//   ANDROID_REGEX.test(platform) || ANDROID_REGEX.test(userAgent);
export const IS_IOS = IOS_REGEX.test(platform) || IOS_REGEX.test(userAgent);
// export const IS_MOBILE = !MSStream && (IS_ANDROID || IS_IOS);
export const IS_WINDOWS =
  WINDOWS_REGEX.test(platform) || WINDOWS_REGEX.test(userAgent);
