import './Variables.scss';
import variables from './variables';
import styled from 'styled-components';

// --------------------- Container

export const Content = styled.div`
  padding: 50px 5%;
  background-color: ${variables.white};
  @media only screen and (max-width: 767px) {
    padding: 50px 20px;
  }
`;

export const BlackCont = styled.div`
  background-color: ${variables.black};
  width: 100%;
  color: ${variables.white};
  padding: 0 5%;
  h2 {
    font-size: 3rem;
    padding: 45px 0 35px;
    text-transform: uppercase;
    font-family: ${variables.HelveticaNeueLTBold};
    line-height: 1.1em;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 767px) {
    padding: 0 20px;
    h2 {
      font-size: 2rem;
      padding: 25px 0 15px;
    }
  }
`;

export const GreyCont = styled(BlackCont)`
  background-color: ${variables.background};
  color: ${variables.black};
  border: 0.5px solid ${variables.grey};
  padding-top: 20px;
  padding-bottom: 15px;
  @media only screen and (max-width: 767px) {
    padding: 15px 20px 10px;
  }
`;

export const SpaceBetweenCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FlexContLeft = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  button {
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const ContImg = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  background-color: ${variables.backgroundImg};
  margin-bottom: 20px;
  img {
    width: 100%;
  }
`;

export const ContConfigurator = styled.div`
  width: 100%;
  max-height: 100vh;
  @media only screen and (max-width: 991px) {
    max-height: -webkit-fill-available; // mobile safari viewport bug fix
  }
  overflow: hidden;
  display: flex;
  flex-direction: row;
  .custom-img-cont {
    width: 75%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    .carousel-configuration-body {
      width: 100%;
      height: calc(100vh - 100px);
    }
    .carousel-configuration-footer {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .configuration-cont {
    width: 25%;
    max-height: 100vh;
    max-height: -webkit-fill-available; // mobile safari viewport bug fix
    background-color: ${variables.white};
    display: flex;
    flex-direction: column;
    text-align: center;

    .customizable-components-list {
      padding: 40px 16px;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      button {
        display: block;
        margin-bottom: 40px;
        background-color: ${variables.white};
        border: 0;
        img {
          width: 150px;
          height: auto;
        }
        p {
          font-size: 15px;
          color: ${variables.black};
          font-family: ${variables.HelveticaNeueLTRegular};
          margin-top: 20px;
          margin-bottom: 0;
        }
        
        &:last-child {
          margin-bottom: 0;
        }
        &:focus,
        &:active{
          outline: 0;
          box-shadow: none !important;
          background-color: ${variables.white};
        }
      }
    }

    .configurations-detail {
      position: relative;
      @media only screen and (min-width: 992px) {
        min-height: 100vh;
        min-height: -webkit-fill-available; // mobile safari viewport bug fix
      }
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .customization-header {
        border-bottom: 1px solid ${variables.greyLight};
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 100%;
        background-color: ${variables.white};
        span {
          font-family: ${variables.HelveticaNeueLTBold};
          font-size: 20px;
          margin-top: 4px;
        }
      }
      .customization-body {
        height: calc(100% - 120px);
        overflow-y: scroll;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        .color-settings-cont {
          box-shadow: none;
          width: 50%;
          height: 160px;
          padding: 10px;
          border: 3px solid ${variables.white};
          background-color: ${variables.white};
          border-radius: 0;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          transition: 0.2s ease-in-out;
          .color {
            width: 58px;
            height: 58px;
            border-radius: 50%;
            border: 2px solid ${variables.greyLight};
            background-color: ${variables.white};
          }
          span {
            margin-top: 10px;
            font-family: ${variables.HelveticaNeueMedium};
            font-size: 12px;
            color: ${variables.black};
            white-space: nowrap;
          }
          &:hover {
            background-color: ${variables.background};
            text-decoration: none;
          }
        }
      }
      .customization-footer {
        border-top: 1px solid ${variables.greyLight};
        height: 60px;
        width: 100%;
        background-color: ${variables.white};
        button {
          width: 50%;
          height: 60px;
          margin: 0;
          background-color: ${variables.white};
          border: 0;
          svg {
            height: 15px;
          }
          &:focus,
          &:active{
            outline: 0;
            box-shadow: none;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 991px){
    flex-direction: column;
    overflow: scroll;
    .custom-img-cont{
      width: 100%;
      height: calc(100vh - 220px);
      .carousel-configuration-body{
        height: calc(100vh - 320px);
      }
      .carousel-configuration-footer{
        position: absolute;
        bottom: -60px;
        height: 60px;
        width: 100%;
        background-color: ${variables.white};
        border-top: 1px solid ${variables.greyLight};
        padding-left: 16px;
        padding-right: 16px;
        a{
          width: 100%;
          button{
            width: 100%;
          }
        }
      }
    }
    .configuration-cont{
      width: 100%;
      height: 220px;
      z-index: 10;

      .customizable-components-list {
        padding: 0 16px;
        overflow-y: hidden;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        height: 160px;
        button{
          margin-bottom: 0;
          margin-right: 40px;
          img{
            max-width: inherit;
          }
          p{
            margin-top: 0;
          }
          &:last-child{
            padding-right: 16px;
            margin-right: 0;
          }
        }
      }

      .configurations-detail{
        .customization-header{
          display: none;  
        }
        .customization-body{
          height: 160px;
          overflow-x: scroll;
          overflow-y: inherit;
          flex-direction: row;
          flex-wrap: nowrap;
          .color-settings-cont{
            box-shadow: none;
            width: 160px;
          }
        }
      }
    }
  }
`;

// --------------------- Text

export const H2Styled = styled.h2`
  color: ${variables.black};
  font-family: ${variables.HelveticaNeueLTBold};
`;

export const H3Styled = styled.h3`
  color: ${variables.black};
  font-family: ${variables.HelveticaNeueLTBold};
  font-size: 25px;
`;

export const H6Styled = styled.h6`
  color: ${variables.black};
  font-family: ${variables.HelveticaNeueLTRegular};
  &.txt-bold {
    font-family: ${variables.HelveticaNeueLTBold};
  }
`;

export const PStyled = styled.p`
  color: ${variables.black};
`;

// --------------------- Button

export const RedButton = styled.button`
  background-color: ${variables.primary};
  color: ${variables.white};
  border: 0;
  border-radius: 0;
  margin: 0;
  font-family: ${variables.HelveticaNeueLTBold};
  font-size: 1rem;
  padding: 15px 50px 10px;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  &:hover {
    background-color: ${variables.black};
  }
`;

export const BlackButton = styled(RedButton)`
  background-color: ${variables.black};
  &:hover {
    background-color: ${variables.primary};
  }
`;

export const BackButton = styled.button`
  background-color: transparent;
  color: ${variables.black};
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  font-family: ${variables.HelveticaNeueLTBold};
  font-size: 20px;
  i {
    margin-right: 10px;
  }
`;

export const ImgButton = styled.button`
  background-color: ${variables.backgroundImg};
  color: ${variables.black};
  border-radius: 5px;
  overflow: hidden;
  margin: 0 4px 4px 0;
  padding: 0;
  border: 1px solid transparent;
  transition: 0.2s ease-in-out;
  img {
    width: 69px;
  }
  &.active,
  &:hover,
  &:focus {
    border-color: ${variables.black};
  }
`;

export const ImgButtonPersonalization = styled(ImgButton)`
  background-image: url(/images/airlander-plus/Tenda1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const DetailButton = styled.button`
  background-color: transparent;
  color: ${variables.black};
  border-radius: 0;
  padding: 0 0 7px 0;
  border: 0;
  border-bottom: 2px solid ${variables.black};
  transition: 0.2s ease-in-out;
  font-family: ${variables.HelveticaNeueLTRegular};
  font-size: 18px;
  &:focus {
    box-shadow: none;
    outline: 0;
  }
`;

export const White100Button = styled.button`
  background-color: ${variables.white};
  color: ${variables.black};
  border-radius: 0;
  border: 2px solid ${variables.grey};
  padding: 12px 12px 5px 12px;
  transition: 0.2s ease-in-out;
  font-family: ${variables.HelveticaNeueLTBold};
  font-size: 18px;
  width: 100%;
  margin: 10px 0;
  text-transform: uppercase;
  &:focus {
    box-shadow: none;
    outline: 0;
  }
  svg {
    margin-top: -6px;
    margin-left: 5px;
  }
  &.windows {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    line-height: 18px;
  }
`;

export const Black100Button = styled(White100Button)`
  background-color: ${variables.primary};
  color: ${variables.white};
  border-color: ${variables.primary};
  &:hover {
    background-color: ${variables.black};
    border-color: ${variables.black};
  }
`;

export const CustomButton = styled.button`
  background-color: ${variables.white};
  color: ${variables.black};
  border: 0;
  border-radius: 20px;
  position: absolute;
  top: 20px;
  left: 40px;
  font-family: ${variables.HelveticaNeueMedium};
  z-index: 1;
  padding: 8px 10px;
  transition: 0.2s ease-in-out;
  span {
    display: none;
    margin-left: 7px;
  }
  &:hover {
    span {
      transition: 0.2s ease-in-out;
      display: inline;
    }
  }
`;

// --------------------- Select

export const SizeLabel = styled.label`
  color: ${variables.blackLabel};
  font-family: ${variables.HelveticaNeueLTBold};
  font-size: 18px;
  margin: 0;
  padding: 16px 16px 0 16px;
  text-transform: uppercase;
`;

export const SizeSelect = styled.select`
  background-color: ${variables.white};
  color: ${variables.blackSelect};
  border-radius: 0;
  border: none;
  border-bottom: 2px solid ${variables.grey};
  padding: 12px 12px 5px 12px;
  transition: 0.2s ease-in-out;
  font-family: ${variables.HelveticaNeueLTBold};
  font-size: 14px;
  width: 100%;
  margin: 5px 0 10px 0;
  text-transform: uppercase;
  &:focus {
    box-shadow: none;
    outline: 0;
  }
  svg {
    margin-top: -6px;
    margin-left: 5px;
  }
`;
