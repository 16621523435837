import it from './it.json';
import en from './en.json';
import fr from './fr.json';
import de from './de.json';

const resources = {
  it,
  en,
  fr,
  de,
};

export default resources;
