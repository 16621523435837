export const yourDesignsEffect = ({ yourDesigns = [] }) => {
  return () => {
    yourDesigns.forEach((design, index) => {
      const { parts = [] } = design;
      parts.forEach(({ ids = [], hex = '' }) => {
        ids.forEach((partId = '') => {
          const partElement = document.querySelector(
            `.your-design-${index} #${partId}_1`
          );
          if (!partElement) return;

          partElement.style.fill = hex;
          const paths = partElement.getElementsByTagName('path');
          for (let i = 0; i < paths.length; i++) {
            paths.item(i).style.fill = hex;
          }
        });
      });
    });
  };
};
