export const getTotalPrice = (size = {}, design = {}) => {
  const { price: basePrice = 0 } = size;
  if (!basePrice) return 0;

  const { parts = [] } = design;
  return (
    basePrice +
    parts.reduce((acc, { price: extraPrice = 0 }) => acc + extraPrice, 0)
  );
};
