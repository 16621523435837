import axios from 'axios';
import qs from 'qs';
import i18next from 'i18next';
import catalog from '../../catalog.json';
import translations from '../../translations';

const {
  REACT_APP_BACKEND_BASE_URL,
  REACT_APP_CONFIGURATIONS_ENDPOINT,
  REACT_APP_STORE_BASE_URL,
  REACT_APP_STORE_TOKEN_URL,
} = process.env;

const supportedLangs = Object.keys(translations);
const [product] = catalog; // Only one product is supported ATM.
const { parts = [] } = product;
const REQUIRED_PARTS_NUMBER = 7;
if (parts.length !== REQUIRED_PARTS_NUMBER)
  throw new Error(`${REQUIRED_PARTS_NUMBER} parts are required for each product.`);
const FILTERS_REQUIRED_NUMBER = REQUIRED_PARTS_NUMBER + 1; // REQUIRED_PARTS_NUMBER + product id

// const hexToName = (partKey, hexString) => {
//   const part = parts.find(({ key }) => key === partKey) || {};
//   const colors = part.colors || [];
//   const color = colors.find(({ hex }) => hex === hexString) || {};
//   return (color.name || '').toUpperCase();
// };

export const getStoreUrl = async (orderData) => {
  if (!orderData) return {
    error: 'COMMON:GENERIC_ERROR',
  };

  const { currentDesign, size } = orderData;
  const { productId } = size || {};
  if (!productId) return {
    error: 'COMMON:GENERIC_ERROR',
  };

  let { parts } = currentDesign || {};
  parts = Array.isArray(parts) ? parts : [];

  const filters = {
    id_product: { $eq: productId },
  };

  parts.forEach((part) => {
    const { colorName, key, hex } = part || {};
    if (
      !colorName ||
      !key ||
      !hex ||
      typeof key !== 'string' ||
      typeof hex !== 'string' ||
      typeof colorName !== 'string'
    )
      return;
    filters[key.toLowerCase()] = {
      // $eq: hexToName(key, hex),
      $eq: colorName.toUpperCase(),
    };
  });

  if (Object.keys(filters).length !== FILTERS_REQUIRED_NUMBER) return {
    error: 'COMMON:GENERIC_ERROR',
  };

  const query = qs.stringify({ filters }, { encodeValuesOnly: true });

  try {
    let [
      { data: token },
      { data: results },
    ] = await Promise.all([
      axios.get(REACT_APP_STORE_TOKEN_URL),
      axios.get(
        `${REACT_APP_BACKEND_BASE_URL}${REACT_APP_CONFIGURATIONS_ENDPOINT}?${query}`
      ),
    ]);
    token = typeof token === 'string' ? token : '';
    token = token.split('=').pop().trim();
    results = results && results.data;
    results = Array.isArray(results) ? results : [];
    const [firstResult] = results;
    const { attributes } = firstResult || {};
    let { id_product_attribute } = attributes || {};
    id_product_attribute = id_product_attribute || '';
    if (!id_product_attribute) return {
      error: 'DETAIL:NOT_AVAILABLE_ERR',
    };
    const storeQueryString = qs.stringify({
      add: 1,
      id_product: productId,
      id_product_attribute,
      token,
    });
    let storeUrl = REACT_APP_STORE_BASE_URL;
    const lang = i18next.language.split('-').shift();
    if (supportedLangs.includes(lang)) {
      storeUrl = storeUrl.replace(/\/it\//, `/${lang}/`);
    }
    return {
      url: `${storeUrl}?${storeQueryString}`,
    };
  }
  catch (e) {
    console.error(e);
    return {
      error: 'COMMON:GENERIC_ERROR',
    };
  }
};
