import React from 'react';
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useQueryParams, NumberParam } from 'use-query-params';
import catalog from '../../catalog.json';
import { useLoading } from '../../commons/Loading';
import { useOrder } from '../../commons/Order';
import { getStoreUrl } from '../../lib/helpers/storeUrl';
import ProductDetailPresentational from './ProductDetailPresentational';

function ProductDetail(props) {
  const [{ item }] = useQueryParams({
    item: NumberParam,
  });
  const [orderData] = useOrder();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();
  const { t } = useTranslation('COMMON');

  const onAddToCart = async () => {
    showLoading();
    const { error, url: storeUrl } = await getStoreUrl(orderData) || {};
    hideLoading();
    storeUrl
      ? window.open(storeUrl, '_self', 'noopener,noreferrer')
      : alert.error(
        t(typeof error === 'string' ? error : 'GENERIC_ERROR')
      );
  };

  if (typeof item !== 'number' || !catalog[item]) {
    return <Redirect to="/" />;
  }

  return (
    <ProductDetailPresentational
      model={catalog[item]}
      item={item}
      orderData={orderData}
      onAddToCart={onAddToCart}
      {...props}
    />
  );
}

export default ProductDetail;
