import React, { createContext, useContext, useReducer } from 'react';
import useLocalStorage from 'react-use-localstorage';
import { initialState, orderReducer } from './orderReducer';

const OrderContext = createContext([{}, (action = {}) => {}]);

const OrderProvider = ({ children }) => {
  const [localStorageData, setLocalStorageData] = useLocalStorage(
    'orderData',
    JSON.stringify(initialState)
  );

  const [state, dispatch] = useReducer(orderReducer, {
    ...JSON.parse(localStorageData),
  });

  const { yourDesigns = [] } = state;

  const dispatchFunction = (action = {}) => {
    const { type, design, size } = action;

    switch (type) {
      case 'clear':
        setLocalStorageData(initialState);
        break;
      case 'saveDesign':
        setLocalStorageData(
          JSON.stringify({
            ...state,
            yourDesigns: [...yourDesigns, design],
          })
        );
        break;
      case 'setSize':
        setLocalStorageData(
          JSON.stringify({
            ...state,
            size,
          })
        );
        break;
      default:
        break;
    }

    return dispatch(action);
  };

  return (
    <OrderContext.Provider value={[state, dispatchFunction]}>
      {children}
    </OrderContext.Provider>
  );
};

const useOrder = () => useContext(OrderContext);

export { OrderProvider, OrderContext, useOrder };
