import React from 'react';
import { CookieBanner as Banner } from '@palmabit/react-cookie-law';

const CookieBanner = () => {
  return (
    <div>
      {process.browser && (
        <Banner
          message="Questo sito o gli strumenti terzi da questo utilizzati si avvalgono di cookie necessari al funzionamento ed utili alle finalità illustrate nella cookie policy. Per saperne di più o negare il consenso, consulta la cookie policy. Accettando acconsenti all’uso dei cookie."
          acceptButtonText="Accetta"
          necessaryOptionText="Necessari"
          statisticsOptionText="Statistiche"
          marketingOptionText="Marketing"
          privacyPolicyLinkText="Cookie Policy"
          policyLink="https://www.autohome-official.com/cookie-policy/"
          showPreferencesOption={false}
          dismissOnScroll={true}
          styles={{
            dialog: {
              position: 'fixed',
              bottom: '0',
              left: '0',
              right: '0',
              zIndex: '100000',
              backgroundColor: '#f8f7f7',
              padding: '10px',
            },
          }}
        />
      )}
    </div>
  );
};

export default CookieBanner;
