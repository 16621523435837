import React, { useState } from 'react';
import './Menu.scss';
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavbarText,
} from 'reactstrap';
import logo from '../../images/autohome-logo-1658219726.jpg';
import LinkMenu from '../../components/LinkMenu';
import Lang from '../Lang';

function Menu({ match }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <Navbar expand="md" light>
        <NavbarBrand href="/" style={{ margin: '0' }}>
          <img
            src={logo}
            alt="AutoHome - configuratore"
            title="AutoHome - configuratore"
          />
        </NavbarBrand>

        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <LinkMenu />
          </Nav>
          <NavbarText>
            <Lang />
          </NavbarText>
        </Collapse>
      </Navbar>
    </>
  );
}

export default Menu;
