import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.scss';
import { version } from './../../../package.json';

const {
  REACT_APP_COOKIE_POLICY_URL = '',
  REACT_APP_LEGAL_NOTES_URL = '',
  REACT_APP_PRIVACY_POLICY_URL = '',
} = process.env;

function Footer() {
  const [t, { language }] = useTranslation('FOOTER');

  const cookieUrl = REACT_APP_COOKIE_POLICY_URL
    .replace('/it', `/${language}`);
  const legalUrl = REACT_APP_LEGAL_NOTES_URL
    .replace('/it', `/${language}`);
  const privacyUrl = REACT_APP_PRIVACY_POLICY_URL
    .replace('/it', `/${language}`);

  return (
    <footer>
      P.I. IT01329850208{' '}
      <a
        href={privacyUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('PRIVACY_POLICY')}
      </a>{' '}
      <a
        href={legalUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('LEGAL_NOTES')}
      </a>{' '}
      <a
        href={cookieUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('COOKIE_POLICY')}
      </a>{' '}
      {t('VERSION')} {version}
    </footer>
  );
}

export default Footer;
