import React, { useState, useEffect } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';
import { getSvgComponent } from '../../lib/helpers/svgComponents';
import { currentDesignEffect } from '../../lib/helpers/currentDesign';

const CarouselConfiguration = (props) => {
  const { model = {}, item = 0, orderData = {} } = props;
  const { images = [], inspirations = [] } = model;
  const { currentDesign = {} } = orderData;
  const { backgroundImages = [] } = currentDesign;
  const [defaultDesign] = inspirations;
  const { parts: defaultParts = [] } = defaultDesign;
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  useEffect(
    currentDesignEffect({
      currentDesign,
      item,
      images,
      defaultParts,
    }),
    [currentDesign]
  );

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = images.map((item, index) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <div className="autohome-img-cont">
          <img
            src={backgroundImages[index] || item.src}
            alt={item.alt}
            className="img-fluid"
          />
          {getSvgComponent(index)}
        </div>
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval={false}
    >
      <CarouselIndicators
        items={slides}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
};

export default CarouselConfiguration;
