export const currentDesignEffect = (params) => {
  const {
    currentDesign = {},
    item = 0,
    images = [],
    defaultParts = [],
  } = params;
  return () => {
    const { item: currentDesignItem } = currentDesign;
    let { parts = defaultParts } = currentDesign;
    parts = currentDesignItem === item ? parts : defaultParts;
    parts.forEach(({ ids = [], hex = '' }) => {
      ids.forEach((partId = '') => {
        images.forEach((image, imageIndex) => {
          const elementId = `${partId}_${imageIndex + 1}`;
          const partElement = document.getElementById(elementId);
          if (!partElement) return;

          partElement.style.fill = hex;
          const paths = partElement.getElementsByTagName('path');
          for (let i = 0; i < paths.length; i++) {
            paths.item(i).style.fill = hex;
          }
        });
      });
    });
  };
};
